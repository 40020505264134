import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import bopp from "../images/bopp.png";
import hm from "../images/hm.png";
import pp from "../images/pp.png";
import ld2 from "../images/ld2.jpg"

const material = [
  { image: `${bopp}`, name: "BOPP",link:'1' },
  // {image:'',name:'POF'},
  {image:`${ld2}`,name:'POF',link:'2'},
  { image: `${pp}`, name: "PP",link:'3' },
  { image: `${hm}`, name: "HM",link:'4' },
];

const Section3 = () => {
  function scrollUp(){
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <div>
      <Container>
        <div className="section3-heading dflex spaceBetween alignCenter">
        <h2 className="sub-hero-text2">Products we manufacture</h2>
        <More className="more">
            <Link onClick={scrollUp} to="products">more</Link>
          </More>
        </div>
        <Cards>
          {material.map((i, index) => (
            <Card key={index}>
              <Link onClick={scrollUp} to={`/product/${i.link}`}><img src={i.image} alt="" /></Link>
              <h3>{i.name}</h3>
            </Card>
          ))}
          
        </Cards>
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: block;
  padding: 100px 50px;
  @media(max-width:768px){
    padding: 50px;
  }
`;

const Cards = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width:768px){
    display:block;
  }
`;

const Card = styled.div`
  margin-top: 40px;
  display: block;
  width: 300px;
  text-align: center;
  @media(max-width:768px){
    width: unset;
  }
  img {
    height: 160px;
    border-radius: 25px;
    @media(max-width:768px){
      width: unset;
    }
  }
  h3{
    margin: 40px 0 0 0;
    font-weight: bolder;
    letter-spacing: 2px;
    text-color:#191e28;
  }
`;

const More = styled.div`
  a {
    text-decoration: none;
    color: white;
    background-color: rgb(25, 30, 40);
    padding: 20px;
    border-radius: 50px;
    transition: 0.7s;
    font-size:x-large;
    @media(max-width:768px){
      font-size:large;
      padding: 10px;
    }
  }
  &:hover a {
    padding: 20px;
    transition: 0.7s;
  }
`;

export default Section3;
