import React,{useState,useEffect} from "react";
import styled from "styled-components";
import logo from "../images/logo8.jpg";
import { Link, Outlet } from "react-router-dom";
// import Drop from "./Drop";
import Dropdown from "./Dropdown";



const Navbar = () => {

  const navbarData = [
    { path: "/", text: "Home" },
    { path: "/products", text: "Products" },
    { path: "/contact", text: "Contact us" },
  ];

  //dropdown
  const [menuOpen, toggleMenuOpen] = useState(false);
  function show(){
    toggleMenuOpen(true)
  }

  function hide(){
    toggleMenuOpen(false);
  }
  
  // mobile
  const [mobile, setMobile] = useState(true);
  useEffect(() => {
    window.innerWidth <= 768 ? setMobile(true) : setMobile(false);
  }, [mobile]);

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  function NavlinksForMobile() {
    isMenuOpen ? setIsMenuOpen(false) : setIsMenuOpen(true);
  }

  return (
    <>
      <Container>
        <Link to='/'><img src={logo} alt="" className="logo" /></Link>
        {
        mobile ? 
        isMenuOpen ? 
        <div className="close" onClick={NavlinksForMobile}>X</div>
        :
        <div className="menu" onClick={NavlinksForMobile}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      :
        <Nav>
          <ul >
            <Link className="color-primary" to="/" onClick={hide} onMouseEnter={hide}>Home</Link>
            <Link className="color-primary" to="products"  onMouseEnter={show} onClick={hide}
          >Product</Link>
            <Link className="color-primary" to="contact" onClick={hide} onMouseEnter={hide}>Contact us</Link>
          </ul>
        </Nav>
        }
        {isMenuOpen && (
              <div className="mobile-menu">
                {navbarData.map((i,index) => (
                  <ul>
                  <li>
                    <Link to={i.path} key={index} onClick={NavlinksForMobile}>{i.text}</Link>
                  </li>
                  </ul>
                ))}
              </div>
            )}
      </Container>
      <div className={`outlet ${isMenuOpen ? "hidden" : ""}`}>
      <Outlet />
      </div>
      {menuOpen &&<Dropdown onMouseLeave={hide}/>}
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    justify-content: space-between;
    padding:20px;
  }
`;

const Nav = styled.div`
  background-color: transparent;
  padding: 22px;
  // margin: 30px 40px;
  border-radius: 7px;
  display: flex;
  width: 100%;
  ul {
    @media(max-width:768px){
      width: unset;
    }
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items:center;
    a {
      text-decoration: none;
      padding: 10px;
      border-radius: 50px;
      transition: 0.5s;
      font-size:25px;
      color: #191e28
    }
    a:hover {
      background:transparent;
      transition: 0.5s;
      color: #191e28;
      text-decoration: underline !important;
      text-underline-offset: 0.5em;
    }
  }
`;

export default Navbar;
