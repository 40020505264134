import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Footer = () => {
  function scrollUp() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return (
    <div>
      <Container>
        <div className="footer">
          <Contact>
            <h3 style={{ textAlign: "center" }}>Get in touch</h3>
            <Link onClick={scrollUp} to="contact">
              <button>Drop us your query here</button>
            </Link>
          </Contact>
          <About>
            <h3 style={{ textAlign: "center" }}>About</h3>
            <p style={{ textAlign: "start", margin: 35 }}>
              BR Plast is one of the reliable manufacturers of plastic pouches
              and e-commerce shipping pouches in India.
              <br />
              <br />
              BR Plast is an eCommerce packaging supply solution and is
              dedicated one-stop solution for every packaging needs for every
              kind of customers. Whether you are a startup or operating an
              online business that needs an ongoing e-commerce package supplies
              or a manufacturer that ships products all over the world, BR Plast
              can help you with your requirement of quality packaging material
              and on-time delivery at your business address.
            </p>
          </About>
          <Language>
            <a href="https://www.indiamart.com/terms-of-use.html">
              <h3>Terms and Conditions</h3>
            </a>
          </Language>
        </div>
        <Copyright>
          <p>&copy; Copyright 2023</p>
          <p>All rights reserved.</p>
        </Copyright>
      </Container>
    </div>
  );
};

const Container = styled.div`
  padding: 50px;
  background-color: #191e28;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: white;
`;

const Contact = styled.div`
width:300px;
@media(max-width:768px){
  width:unset;
}

button{
  margin:50px;
  border: 0px;
    border-radius: 20px;
    background: white;
    padding: 10px;
    transition:0.7s;
    color:rgb(25, 30, 40);
    font-weight:600;
}
&:hover {
  button {
    padding:20px;
    transition:0.7s;
  }
`;

const Language = styled.div`
  a {
    text-decoration: none;
    color: white;
  }
`;

const About = styled.div`
  width: 700px;
  @media (max-width: 768px) {
    width: unset;
  }
`;

const Copyright = styled.div`
  display: block;
  margin: 30px 0 0 0;
  p {
    text-align: center;
    margin: 0;
  }
`;

export default Footer;
